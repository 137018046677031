<template>
  <div>
    <v-container>
      <div class="not_found d-flex align-center justify-center">
        <LottieAnimation
          class="anim"
          ref="anim"
          :animationData="require('@/assets/95560-error-404.json')"
          :loop="true"
        ></LottieAnimation>
      </div>
    </v-container>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "PageNotFound",
  components: {
    LottieAnimation,
  },
};
</script>

<style lang="scss" scoped>
.anim {
  width:50%;
  @media(max-width:767px) {
    width:80%;
  }
}
.not_found {
  height: calc(100vh - 200px);
}
</style>
